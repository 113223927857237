import { dashboardAction } from '@store/dashboard/dashboardSlice';
import { useSelector,useDispatch } from 'react-redux'
import { useSession} from "next-auth/react"
import { useState, useEffect} from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Typography, useTheme, Grid, Container } from '@mui/material';
import { FaDog, FaCat } from "react-icons/fa";
import PetsIcon from '@mui/icons-material/Pets';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

export const DashboardDoughnutChallenge = () => {
  const { data: session, status } = useSession();
  const userToken = session.user.userToken;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [ dogRate, setDogRate ] = useState(0);
  const [ catRate, setCatRate ] = useState(0);
  const [ dogSurgeryRate, setDogSurgeryRate ] = useState(0);
  const [ dogNotSurgeryRate, setDogNotSurgeryRate ] = useState(0);
  const [ catSurgeryRate, setCatSurgeryRate ] = useState(0);
  const [ catNotSurgeryRate, setCatNotSurgeryRate ] = useState(0);

  const [ dogList, setDogList ] = useState([]);
  const [ dogTotalCnt, setDogTotalCnt ] = useState(0);
  const [ catList, setCatList ] = useState([]);
  const [ catTotalCnt, setCatTotalCnt ] = useState(0);

  const [ dogEtcList, setDogEtcList ] = useState([]);
  const [ dogEtcTotalCnt, setDogEtcTotalCnt ] = useState(0);
  const [ catEtcList, setCatEtcList ] = useState([]);
  const [ catEtcTotalCnt, setCatEtcTotalCnt ] = useState(0);

  const colorList = ['#6D9EDB', '#F0B214', '#FF416A', '#2FB4BD', '#2F2FBD', '#9CBD2F', '#AF2FBD', '#3581D8', '#CB2042', '#D88735'];

  useEffect(() => {
    dispatch(dashboardAction.getDashboardTotalPet({userToken}));
  }, []);
  
  const { data : getDashboardTotalPet, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardTotalPet
  })
  
  useEffect(() => {
    if(getDashboardTotalPet){
      setDogList(getDashboardTotalPet.pet_kind_mst_list.filter(pet => pet.type === '개'));
      setCatList(getDashboardTotalPet.pet_kind_mst_list.filter(pet => pet.type === '고양이'));

      setDogEtcList(getDashboardTotalPet.user_pet_info_list.filter(pet => pet.type === '개'));
      setCatEtcList(getDashboardTotalPet.user_pet_info_list.filter(pet => pet.type === '고양이'));
      
      setDogRate(getDashboardTotalPet.dog_count / getDashboardTotalPet.total * 100 )
      setCatRate(getDashboardTotalPet.cat_count / getDashboardTotalPet.total * 100 )

      let rate = getDashboardTotalPet.dog_surgery_count / getDashboardTotalPet.dog_count * 100 ;
      setDogSurgeryRate(rate)
      setDogNotSurgeryRate(100 - rate)
      rate = getDashboardTotalPet.cat_surgery_count / getDashboardTotalPet.cat_count * 100 ;
      setCatSurgeryRate(getDashboardTotalPet.cat_surgery_count / getDashboardTotalPet.cat_count * 100 )
      setCatNotSurgeryRate(100 - rate)
    }
  }, [getDashboardTotalPet]);

  useEffect(() => {
    if(dogList){
      let sum = 0;
      dogList.forEach(function(el){sum+=el.cnt;});
      setDogTotalCnt(sum);
    }
  }, [dogList]);
  
  useEffect(() => {
    if(catList){
      let sum = 0;
      catList.forEach(function(el){sum+=el.cnt;});
      setCatTotalCnt(sum);
    }
  }, [catList]);

  useEffect(() => {
    if(dogEtcList){
      let sum = 0;
      dogEtcList.forEach(function(el){sum+=el.cnt;});
      setDogEtcTotalCnt(sum);
    }
  }, [dogEtcList]);
  
  useEffect(() => {
    if(catEtcList){
      let sum = 0;
      catEtcList.forEach(function(el){sum+=el.cnt;});
      setCatEtcTotalCnt(sum);
    }
  }, [catEtcList]);

  if(!getDashboardTotalPet) return <>로딩중...</>

  const petDataset = {
    datasets: [
      {
        data: [dogRate.toFixed(1), catRate.toFixed(1)],
        backgroundColor: ['#F0B214', '#6D9EDB'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['Dog', 'Cat']
  };
  const types = [
    {
      title: 'Dog',
      value: dogRate,
      icon: FaDog,
      color: '#F0B214'
    },
    {
      title: 'Cat',
      value: catRate,
      icon: FaCat,
      color: '#6D9EDB'
    }
  ];

  const dogDataset = {
    datasets: [
      {
        data: [dogSurgeryRate.toFixed(1), dogNotSurgeryRate.toFixed(1)],
        backgroundColor: ['#F0B214', '#6D9EDB'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['중성화 O', '중성화 X']
  };
  const dogTypes = [
    {
      title: '중성화 O',
      value: dogSurgeryRate,
      icon: HeartBrokenIcon,
      color: '#F0B214'
    },
    {
      title: '중성화 X',
      value: dogNotSurgeryRate,
      icon: FavoriteBorderIcon,
      color: '#6D9EDB'
    }
  ];

  const catDataset = {
    datasets: [
      {
        data: [catSurgeryRate.toFixed(1), catNotSurgeryRate.toFixed(1)],
        backgroundColor: ['#F0B214', '#6D9EDB'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['중성화 O', '중성화 X']
  };
  const catTypes = [
    {
      title: '중성화 O',
      value: catSurgeryRate,
      icon: HeartBrokenIcon,
      color: '#F0B214'
    },
    {
      title: '중성화 X',
      value: catNotSurgeryRate,
      icon: FavoriteBorderIcon,
      color: '#6D9EDB'
    }
  ];

  const dogKindDataset = {
    datasets: [
      {
        data: dogList.map(row=> (row.cnt / dogTotalCnt * 100).toFixed(1)),
        backgroundColor: colorList,
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: dogList.map(row=>row.name_kr)
  };
  const catKindDataset = {
    datasets: [
      {
        data: catList.map(row=> (row.cnt / catTotalCnt * 100).toFixed(1)),
        backgroundColor: colorList,
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: catList.map(row=>row.name_kr)
  };
  const dogEtcDataset = {
    datasets: [
      {
        data: dogEtcList.map(row=> (row.cnt / dogEtcTotalCnt * 100).toFixed(1)),
        backgroundColor: colorList,
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: dogEtcList.map(row=>row.etc_name)
  };
  const catEtcDataset = {
    datasets: [
      {
        data: catEtcList.map(row=> (row.cnt / catEtcTotalCnt * 100).toFixed(1)),
        backgroundColor: colorList,
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: catEtcList.map(row=>row.etc_name)
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Container maxWidth={false} style={{padding:"0px"}}>
      <Grid container spacing={2}>
        <Grid item md={4} >
          <Card>
            <CardHeader title="반려동물 등록비율" avatar={<PetsIcon/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 200,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={petDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  // pt: 2
                }}
              >
                {types.map(({
                  color,
                  icon: Icon,
                  title,
                  value
                }) => (
                  <Box
                    key={title}
                    sx={{
                      p: 1,
                      textAlign: 'center'
                    }}
                  >
                    <Icon color="action" />
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {title}
                    </Typography>
                    <Typography
                      style={{ color }}
                      variant="h4"
                    >
                      {value.toFixed(1)}
                      %
                    </Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardHeader title="강아지 중성화비율" avatar={<FaDog/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 200,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={dogDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  // pt: 2
                }}
              >
                {dogTypes.map(({
                  color,
                  icon: Icon,
                  title,
                  value
                }) => (
                  <Box
                    key={title}
                    sx={{
                      p: 1,
                      textAlign: 'center'
                    }}
                  >
                    <Icon color="action" />
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {title}
                    </Typography>
                    <Typography
                      style={{ color }}
                      variant="h4"
                    >
                      {value.toFixed(1)}
                      %
                    </Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardHeader title="고양이 중성화비율" avatar={<FaCat/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 200,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={catDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  // pt: 2
                }}
              >
                {catTypes.map(({
                  color,
                  icon: Icon,
                  title,
                  value
                }) => (
                  <Box
                    key={title}
                    sx={{
                      p: 1,
                      textAlign: 'center'
                    }}
                  >
                    <Icon color="action" />
                    <Typography
                      color="textPrimary"
                      variant="body1"
                    >
                      {title}
                    </Typography>
                    <Typography
                      style={{ color }}
                      variant="h4"
                    >
                      {value.toFixed(1)}
                      %
                    </Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6} >
          <Card>
            <CardHeader title="강아지 품종" avatar={<FaDog/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 400,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={dogKindDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {dogList.length > 0 &&
                  <Grid container spacing={2}>
                  {dogList.map((dog,index) => (
                    <>
                      <Grid item md={3} >
                        <Box
                        key={dog.name_kr}
                        sx={{
                          p: 1,
                          textAlign: 'center'
                        }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {dog.name_kr}
                          </Typography>
                          <Typography
                            style={{ color:colorList[index] }}
                            variant="h5"
                          >
                            {(dog.cnt / dogTotalCnt * 100).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                  </Grid>
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} >
          <Card>
            <CardHeader title="고양이 품종" avatar={<FaCat/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 400,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={catKindDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {catList.length > 0 &&
                  <Grid container spacing={2}>
                  {catList.map((cat,index) => (
                    <>
                      <Grid item md={3} >
                        <Box
                        key={cat.name_kr}
                        sx={{
                          p: 1,
                          textAlign: 'center'
                        }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {cat.name_kr}
                          </Typography>
                          <Typography
                            style={{ color:colorList[index] }}
                            variant="h5"
                          >
                            {(cat.cnt / catTotalCnt * 100).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                  </Grid>
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} >
          <Card>
            <CardHeader title="강아지 기타(직접입력)" avatar={<FaDog/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 400,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={dogEtcDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {dogEtcList.length > 0 &&
                  <Grid container spacing={2}>
                  {dogEtcList.map((dog,index) => (
                    <>
                      <Grid item md={3} >
                        <Box
                        key={dog.etc_name}
                        sx={{
                          p: 1,
                          textAlign: 'center'
                        }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {dog.etc_name}
                          </Typography>
                          <Typography
                            style={{ color:colorList[index] }}
                            variant="h5"
                          >
                            {(dog.cnt / dogEtcTotalCnt * 100).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                  </Grid>
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} >
          <Card>
            <CardHeader title="고양이 기타(직접입력)" avatar={<FaCat/>} />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  height: 400,
                  position: 'relative'
                }}
              >
                <Doughnut
                  data={catEtcDataset}
                  options={options}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {catEtcList.length > 0 &&
                  <Grid container spacing={2}>
                  {catEtcList.map((cat,index) => (
                    <>
                      <Grid item md={3} >
                        <Box
                        key={cat.etc_name}
                        sx={{
                          p: 1,
                          textAlign: 'center'
                        }}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {cat.etc_name}
                          </Typography>
                          <Typography
                            style={{ color:colorList[index] }}
                            variant="h5"
                          >
                            {(cat.cnt / catEtcTotalCnt * 100).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ))}
                  </Grid>
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};