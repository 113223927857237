import Head from 'next/head';
import { Box, Container, Grid } from '@mui/material';
import { DashboardChallenge } from '@components/dashboard/DashboardChallenge';
import { DashboardPet } from '@components/dashboard/DashboardPet';
import { DashboardJudged } from '@components/dashboard/DashboardJudged';
import { DashboardEnteredStatus } from '@components/dashboard/DashboardEnteredStatus';
import { DashboardPackage } from '@components/dashboard/DashboardPackage';
import { DashboardLayout } from '@components/layout/dashboard-layout';
import { useSession} from "next-auth/react"
import { LineChart } from '@components/dashboard/DashboardChartResults';
import { DashboardDoughnutChallenge } from '@components/dashboard/DashboardDoughnutChallenge';

export default function Dashboard() {
  const { data: session, status } = useSession();

  return (
    <>
        <Head>
          <title>Dashboard</title>
        </Head>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth={false} >
            <Box sx={{mt: -10, height: '100%'}}>
              <LineChart/>
            </Box>      
            <Grid container spacing={2}>
              <Grid item sx={6} md={12}>
                <DashboardChallenge />
              </Grid>
              <Grid item sx={6} md={9}>
                <DashboardEnteredStatus />   
              </Grid>
              <Grid item sx={6} md={3}>
                <DashboardJudged />
                <DashboardPet />
              </Grid>
              <Grid item sx={6} md={12}>
                <DashboardPackage/>
              </Grid>
              <Grid item sx={6} md={12}>
                <DashboardDoughnutChallenge/>
              </Grid>
            </Grid>
          </Container>
        </Box>
    </>
  );
}

Dashboard.getLayout = (page) => (
  <DashboardLayout>
    {page}
  </DashboardLayout>
);

