import { dashboardAction } from "@store/dashboard/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useSession } from "next-auth/react";
import { useState, useEffect } from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { Card, CardContent, Typography, Grid, Box, Divider } from "@mui/material";

export const DashboardChallenge = () => {
  const { data: session, status } = useSession();
  const userToken = session.user.userToken;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardChallenge({ userToken }));
  }, []);

  const { data, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardChallenge;
  });

  if (!data) return <>로딩중...</>;

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        mt={2}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          <DirectionsRunIcon /> 챌린지
        </Typography>
      </Box>
      <Grid container spacing={3} columns={8}>
        <Grid item xs={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={4}>
                <Grid item xs={4}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      진행 중
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.possible_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    1분챌린지 : {Number(data.possible_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    놀로전문가교육 : {Number(data.possible_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    댕냥상식카드 : {Number(data.possible_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={4}>
                <Grid item xs={4}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      신규
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.today_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    1분챌린지 : {Number(data.today_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    놀로전문가교육 : {Number(data.today_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    댕냥상식카드 : {Number(data.today_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
