
import { dashboardAction } from '@store/dashboard/dashboardSlice';
import { useSelector,useDispatch } from 'react-redux'
import { useSession} from "next-auth/react"
import { useState, useEffect} from 'react';
import GavelIcon from '@mui/icons-material/Gavel';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
} from '@mui/material';

export const DashboardJudged = () => {

  const { data: session, status } = useSession();
  const userToken = session.user.userToken;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardJudged({userToken}));
  }, []);

  const { data, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardJudged
  })
  
  if(!data) return <>로딩중...</>
  
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        mt={1}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
          <GavelIcon /> 1분챌린지 심사
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        columns={2}
      >
        <Grid item xs={2} >
          <Card
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Grid
                container
                spacing={3}
                columns={2}
              >
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    전체 : {Number(data.total).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    대기 : {Number(data.wait_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    승인 : {Number(data.approval_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    성공 : {Number(data.success_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    거절 : {Number(data.refuse_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    실패 : {Number(data.fail_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    성공률 : {((data.approval_count+data.success_count) / (data.total - data.wait_count) * 100).toFixed(1)}%
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};