import React from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useSession} from "next-auth/react"
import { useEffect } from 'react';
import { Line }  from 'react-chartjs-2';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  Card,
  CardContent,
  Box,
  Typography,
} from '@mui/material';

import { dashboardAction } from '@store/dashboard/dashboardSlice';

export const LineChart = (props) => {
  const { data: session, status } = useSession();
  const userToken = session.user.userToken;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardChart({userToken}));
  }, []);

  const { data : getDashboardChart, errorChart, loadingChart } = useSelector((state) => {
    return state.dashboard.getDashboardChart
  })
  
  if(!getDashboardChart) return <>로딩중...</>

  const data = {
      labels: getDashboardChart.challenge_mst_info_list.map(row=>row.date),
      datasets: [
        {
          label: '챌린지 조회',
          borderColor: '#5B8BCA',
          hoverBackgroundColor: '#5B8BCA',
          backgroundColor: '#5B8BCA',
          borderWidth: 3,
          data: getDashboardChart.challenge_user_info_list.map(row=>row.check_count)
        },
        {
          label: '챌린지 완료',
          borderColor: '#C47B3E',
          hoverBackgroundColor: '#C47B3E',
          backgroundColor: '#C47B3E',
          borderWidth: 3,
          data: getDashboardChart.challenge_user_info_list.map(row=>row.complet_count)
        },
        {
          label: '챌린지 보상',
          borderColor: '#606060',
          hoverBackgroundColor: '#606060',
          backgroundColor: '#606060',
          borderWidth: 3,
          data: getDashboardChart.challenge_user_info_list.map(row=>row.reward_count)
        },
        {
          type: 'bar',
          label: '진행중인 챌린지',
          borderColor: '#FFD338',
          hoverBackgroundColor: '#FFD338',
          backgroundColor: '#FFD338',
          borderWidth: 1,
          data: getDashboardChart.challenge_mst_info_list.map(row=>row.ing_count)
        },
        {
          type: 'bar',
          label: '신규 챌린지',
          borderColor: '#6D9EDB',
          hoverBackgroundColor: '#6D9EDB',
          backgroundColor: '#6D9EDB',
          borderWidth: 1,
          data: getDashboardChart.challenge_mst_info_list.map(row=>row.new_count)
        },
      ]
    }; 

  const options = {
      maintainAspectRatio: false,
      cutoutPercentage: 50,
      legend: {
        display: true,
        position: 'left',
        labels: {
            fontSize: 12,
            fontFamily: 'sans-serif',
            fontColor: '#000000',
            fontStyle: 'bold'
        }
      },
      scales: {
        y: {
            min: 0,
            ticks: {
                stepSize: 1
            },
            suggestedMax: Math.ceil(Math.max.apply(Math, getDashboardChart.challenge_user_info_list.map(function(o) { return o.check_count; }))/ 10) * 10,
        }
    }
    }

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mt: 4
        }}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
          <DashboardIcon /> 챌린지 현황
        </Typography>
      </Box>
      <Card 
      {...props}
      >
      <CardContent sx={{height:'calc(var(--vh, 1vh) * 55)'}}>
        <Line 
            data={data}
            options={options}
        />
      </CardContent>
    </Card>
  </>
  );
};