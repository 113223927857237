
import { dashboardAction } from '@store/dashboard/dashboardSlice';
import { useSelector,useDispatch } from 'react-redux'
import { useSession} from "next-auth/react"
import { useState, useEffect} from 'react';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
} from '@mui/material';

export const DashboardPackage = () => {

  const { data: session, status } = useSession();
  const userToken = session.user.userToken;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardPackage({userToken}));
  }, []);

  const { data, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardPackage
  })
  
  if(!data) return <>로딩중...</>

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        mt={1}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
          <RocketLaunchIcon /> 챌린지시리즈
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        columns={5}
      >
        <Grid item xs={2} >
          <Card
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Grid
                container
                spacing={3}
                columns={2}
              >
                <Grid item xs={2}>
                  <Divider textAlign="left">  
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      챌린지시리즈
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    진행 중 : {Number(data.possible_package).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    신규 : {Number(data.today_package).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Divider textAlign="left">  
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      1분챌린지
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    진행 중 : {Number(data.possible_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    신규 : {Number(data.today_challenge).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Grid
                container
                spacing={3}
                columns={3}
              >
                <Grid item xs={3}>
                  <Divider textAlign="left">  
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      1분챌린지 참여
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    전체 : {Number(data.total).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    어제 : {Number(data.yesterday_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    오늘 : {Number(data.today_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Divider textAlign="left">  
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="h6"
                    >
                      1분챌린지 보상
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    전체 : {Number(data.total_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    어제 : {Number(data.yesterday_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    오늘 : {Number(data.today_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};