import { dashboardAction } from "@store/dashboard/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useSession } from "next-auth/react";
import { useState, useEffect } from "react";
import QuizIcon from "@mui/icons-material/Quiz";
import { Card, CardContent, Typography, Grid, Box, Divider } from "@mui/material";

export const DashboardEnteredStatus = () => {
  const { data: session, status } = useSession();
  const userToken = session.user.userToken;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardEnteredStatus({ userToken }));
  }, []);

  const { data, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardEnteredStatus;
  });

  if (!data) return <>로딩중...</>;

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        mt={1}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          <QuizIcon /> 챌린지 참여
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={1}>
                <Grid item xs={1}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      모든 챌린지
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    비율 : 100%
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider textAlign="left">보상</Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_reward_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    보상 지급률 : {((data.total_reward_count / data.total) * 100).toFixed(1)}%
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={1}>
                <Grid item xs={1}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      1분챌린지
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    비율 : {((data.total_one_minute_challenge / data.total) * 100).toFixed(1)}%
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider textAlign="left">보상</Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_reward_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_reward_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_reward_one_minute_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    보상 지급률 :{" "}
                    {(
                      (data.total_reward_one_minute_challenge / data.total_one_minute_challenge) *
                      100
                    ).toFixed(1)}
                    %
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={1}>
                <Grid item xs={1}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      놀로전문가교육
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    비율 : {((data.total_quiz_challenge / data.total) * 100).toFixed(1)}%
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Divider textAlign="left">보상</Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_reward_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_reward_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_reward_quiz_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    보상 지급률 :{" "}
                    {((data.total_reward_quiz_challenge / data.total_quiz_challenge) * 100).toFixed(
                      1
                    )}
                    %
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Grid container spacing={3} columns={1}>
                <Grid item xs={1}>
                  <Divider textAlign="left">
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      댕냥상식카드
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    비율 : {((data.total_card_challenge / data.total) * 100).toFixed(1)}%
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Divider textAlign="left">보상</Divider>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    전체 : {Number(data.total_reward_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    어제 : {Number(data.yesterday_reward_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    오늘 : {Number(data.today_reward_card_challenge).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography color="textSecondary" gutterBottom variant="subtitle2">
                    보상 지급률 :{" "}
                    {((data.total_reward_card_challenge / data.total_card_challenge) * 100).toFixed(
                      1
                    )}
                    %
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
