
import { dashboardAction } from '@store/dashboard/dashboardSlice';
import { useSelector,useDispatch } from 'react-redux'
import { useSession} from "next-auth/react"
import { useState, useEffect} from 'react';
import PetsIcon from '@mui/icons-material/Pets';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
} from '@mui/material';

export const DashboardPet = () => {

  const { data: session, status } = useSession();
  const userToken = session.user.userToken;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dashboardAction.getDashboardPet({userToken}));
  }, []);

  const { data, error, loading } = useSelector((state) => {
    return state.dashboard.getDashboardPet
  })
  
  if(!data) return <>로딩중...</>
  
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        mt={1}
      >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
          <PetsIcon /> 반려동물
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        columns={1}
      >
        <Grid item xs={1} >
          <Card
            sx={{ height: '100%' }}
          >
            <CardContent>
              <Grid
                container
                spacing={3}
                columns={2}
              >
                <Grid item xs={2}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    전체 : {Number(data.total).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    어제 : {Number(data.yesterday_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    오늘 : {Number(data.today_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    강아지 : {Number(data.dog_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    강아지(기타) : {Number(data.dog_etc_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    고양이 : {Number(data.cat_count).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    고양이(기타) : {Number(data.cat_etc_count).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};